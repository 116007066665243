// pdfReducer.js

export const pdfReducer = (state, action) => {
    console.log('Action received in pdfReducer:', action);
    switch (action.type) {
      case 'SET_PDF_URL':
        if (!action.pdfUrl) {
          console.error('PDF URL is undefined or null:', action);
          return state; // Return the current state if the URL is undefined to avoid wiping existing data
        }
        const newPdfState = {
          ...state,
          [action.fieldName]: action.pdfUrl
        };
        console.log('New state after PDF update:', newPdfState);
        return newPdfState;
      default:
        console.log('Unhandled action type in pdfReducer:', action.type);
        return state;
    }
  };
  
  // imgReducer.js
  export const imgReducer = (state, action) => {
    console.log('Action received in imgReducer:', action);
    switch (action.type) {
      case 'SET_IMG_URL':
        if (!action.imgUrl) {
          console.error('Image URL is undefined or null:', action);
          return state; // Return the current state if the URL is undefined
        }
        const newImgState = {
          ...state,
          [action.fieldName]: action.imgUrl
        };
        console.log('New state after image update:', newImgState);
        return newImgState;
      default:
        console.log('Unhandled action type in imgReducer:', action.type);
        return state;
    }
  };
  
// varfileReducer.js
export const varfileReducer = (state, action) => {
  console.log('Action received in varfileReducer:', action);
  switch (action.type) {
    case 'SET_VAR_FILE_URL':
      if (!action.varUrl) {
        console.error('Var File URL is undefined or null:', action);
        return state;
      }
      const newVarFileState = {
        ...state,
        [action.fieldName]: action.varUrl
      };
      console.log('New state after varFile update:', newVarFileState);
      return newVarFileState;

    case 'SET_VAR_FILE_NAME':
      if (!action.fileName) {
        console.error('Var File Name is undefined or null:', action);
        return state;
      }
      const newFileNameState = {
        ...state,
        [`${action.fieldName}_filename`]: action.fileName
      };
      console.log('New state after fileName update:', newFileNameState);
      return newFileNameState;

    default:
      console.log('Unhandled action type in varfileReducer:', action.type);
      return state;
  }
};


// countReducer.js

export const countarrayReducer = (state, action) => {
  console.log('Action received in countReducer:', action);
  switch (action.type) {
    case 'SET_MAX_INPUT_COUNT':
      if (typeof action.count !== 'number') {
        console.error('Invalid count provided:', action);
        return state; // Return current state if the count is invalid
      }
      const fieldName = action.fieldName;
      const currentCount = state[fieldName] || 0;
      const newCountState = {
        ...state,
        [fieldName]: Math.max(currentCount, action.count)
      };
      console.log('New state after max input count update:', newCountState);
      return newCountState;

    default:
      console.log('Unhandled action type in countReducer:', action.type);
      return state;
  }
};

  