import React from 'react';
import { FormGroup, Accordion } from 'react-bootstrap';

const ElementInfo = ({ Infotitle, Infodescr, children, ...props }) => {
    // Check if there is any content to display in the Accordion.Body
    const hasContent = Infodescr || React.Children.count(children) > 0;
    // Set a class to control the styling and functionality based on content availability
    const accordionClass = hasContent ? '' : 'accordion-no-content';

    return (
        <FormGroup>
            <Accordion flush className={`info ${accordionClass}`}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <img src='./img/icons/icon_info.svg' style={{ marginRight: "10px" }} alt="Info"></img>
                        {Infotitle}
                    </Accordion.Header>
                    {hasContent && (
                        <Accordion.Body>
                            
                            {Infodescr}
                            {children}
                            
                        </Accordion.Body>
                    )}
                </Accordion.Item>
            </Accordion>
        </FormGroup>
    );
};

export default ElementInfo;
