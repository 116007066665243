import React from 'react';
import { useField } from 'formik';
import { FormGroup, FormLabel } from 'react-bootstrap';

const FormText = ({ name, labelText, onChange }) => {
  const [field, meta, helpers] = useField(name);
  const additionalClass = meta.touched && field.value ? "form-text-selected" : "";
  const additionalClass_label = meta.touched && field.value ? "formgroup-selected" : "";
  
  // Compose your custom display value here if needed, or handle it externally
  return (
    <FormGroup className={`formgroup ${additionalClass_label}`}>
      <FormLabel htmlFor={name}>{labelText}</FormLabel>
      <input
        type="text"
        {...field}
        className={`form-text ${additionalClass}`}
        value={field.value}  // This is controlled by Formik
        onChange={(e) => {
          field.onChange(e);  // Call Formik's handler
          if (onChange) onChange(e);  // Call the custom onChange handler if provided
        }}
      />
    </FormGroup>
  );
};

export default FormText;
