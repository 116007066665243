import React, { useEffect } from 'react';
import { Button, FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import { useFileHandler } from '../../functions/formik/useFileHandler';
import { useFormikContext } from 'formik';

const FormFileSelectPDF = ({ label, name, dispatch, pdfState, desiredWidth, desiredHeight, cropmark, unit, onChange, imgnaming }) => {
  // console.log('Received dispatch in FormFileSelectPDF:', typeof dispatch);
  const { handleFileChange, fileInfo, errorMessage } = useFileHandler(name, cropmark, desiredWidth, desiredHeight, dispatch);
  const { values, setFieldValue } = useFormikContext();
  const comp_filename_pdf = `${imgnaming}_${name}.pdf`;
  const pdfUrl = pdfState[name];
  // console.log('Current PDF State in FormFileSelectPDF:', pdfState);  // Log the entire state
  // console.log('name in formfileselectPDF', name);  // Log the name used to access pdfState
  // console.log('Current PDF URL in FormFileSelectPDF:', pdfUrl);  // Ensure this is logged in the rendering component

  useEffect(() => {
    console.log('FormFileSelectPDF useEffect triggered');
  }, [pdfUrl]);

  const handleFileInputChangePDF = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
        handleFileChange(event);  // Assuming this does additional necessary setups
        
        const pdfUrl = URL.createObjectURL(file); // Create a URL for the PDF
        dispatch({ type: 'SET_PDF_URL', fieldName: name, pdfUrl }); // Dispatch action to update state
        // console.log(`PDF URL set for ${name}:`, pdfUrl);

        const newFilename = `${imgnaming}_${name}.pdf`;
        setFieldValue(name, file);
        setFieldValue(`${name}_filename`, newFilename);
        // console.log(`File selected for ${name}:`, file);
        // console.log(`Filename set for ${name}:`, newFilename);
        
        if (onChange) {
            onChange({ filename: newFilename });
        }
    }
};


  useEffect(() => {
    if (values[name]) {
      setFieldValue(`${name}_filename`, comp_filename_pdf);
    }
  }, [imgnaming, name, comp_filename_pdf, setFieldValue, values]);

  return (
    <Row style={{ marginBottom: '20px' }}>
      <Col md='8'>
      <div style={{ minWidth:'100%', backgroundColor:'#ededed', padding:'10px', border:"1px solid grey",}}>
        <FormGroup className="formgroup" style={{backgroundColor:'none!important'}}>
          {label && <FormLabel htmlFor={name}>
            <div>
              <div className="btn_fileselect_inf btn_fileselect_info_first">{label}</div>
              <div className='btn_fileselect_inf' style={{ fontWeight: 'normal' }}>Format: {desiredWidth}{unit} x {desiredHeight}{unit}</div>
              <div className='btn_fileselect_inf' style={{ fontWeight: 'normal' }}>Dateiname: {fileInfo.originalFilename}</div>
            </div>
          </FormLabel>}
          <input
            id={`${name}`}
            name={`${name}`}
            type="file"
            accept="application/pdf"
            onChange={handleFileInputChangePDF}
            style={{ display: "none" }}
          />

          <Button as="label" htmlFor={`${name}`} className='btn_fileselect_select_pdf'>
            Datei auswählen
          </Button>

          <Row>
          <Col md="auto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h4>Dateibenennung</h4>
          </Col>

            <Col>
              <input
                type="text"
                name={`${name}_filename`}
                className={`form-text-info`}
                value={values[`${name}_filename`] || comp_filename_pdf}
                readOnly
                style={{ userSelect: "none", cursor: "default" }}
              />
            </Col>
          </Row>
        </FormGroup>
        </div>
        {errorMessage && 
        <div className="warn-no-crop">
          {errorMessage}
        </div>}

        {fileInfo.metadata?.pages && fileInfo.metadata.pages.length > 0 && (
          <>
            <Row style={{ marginTop: "20px" }}>
              <Col xs={1}>Seite:</Col>
              <Col>Größe:</Col>
              <Col>Annotationen:</Col>
            </Row>
            {fileInfo.metadata.pages.map((info, index) => (
              <div key={index} style={{ borderBottom: '1px solid grey', backgroundColor:'' }}>
                <Row>
                  <Col xs={1}><p>{info.pageIndex}</p></Col>
                  <Col><p>{info.widthCm} cm x {info.heightCm} cm</p></Col>
                  <Col><p>{info.annotations.join(", ")}</p></Col>
                </Row>
              </div>
            ))}
          </>
        )}
      </Col>
      <Col>
        {pdfUrl ? (
          <iframe src={pdfUrl} style={{ width: '100%', height: '100%', minHeight: '800px', marginTop: '20px' }} title="PDF Preview"></iframe>
        ) : (
          <div className='form_file_pdf_noPDF'>Noch kein PDF ausgewählt</div>
        )}
      </Col>
      <div style={{borderBottom:'1px solid black', marginBottom:"20px", marginTop:"20px"}}></div>
    </Row>
  );
};

export default FormFileSelectPDF;