import React, {useEffect} from "react";
import { useField, useFormikContext  } from "formik";

import { FormGroup, FormLabel } from "react-bootstrap";
import {
  Button,
  FormControl,
  FormSelect,
  Accordion,
  Card,
  Tab,
  Tabs,
  Row,
  Nav,
  Col,
} from "react-bootstrap";


import FormCompText from "./../formik/form-text";
import FormCompCheckbox from "./../formik/form-checkbox";
import { useUrlValidation } from '../../functions/formik/use-url-val'; // adjust the path as necessary
import * as Yup from 'yup';

const FormTextAllow = ({ name, labelText, onChange, textTrue, textFalse, urlval}) => {
  const validateUrl = useUrlValidation(urlval);
  const [field, meta, helpers] = useField({ name, validate: validateUrl });
  
  const additionalClass = meta.touched && field.value ? "form-text-selected" : "";
  const additionalClass_label = meta.touched && field.value ? "formgroup-selected" : "";


  // Compose your custom display value here if needed, or handle it externally
  return (
    <FormGroup className={`formgroup ${additionalClass_label}`}>
      <Row>
        <div><FormLabel htmlFor={name} >{labelText}</FormLabel></div>
        <Col>
          <input
            type="text"
            {...field}
            
            className={`form-text ${additionalClass}`}
            value={field.value} // This is controlled by Formik
            onChange={(e) => {
              helpers.setValue(e.target.value);
              if (onChange) onChange(e);  // Custom handler if provided
            }}
          />
         
          {meta.touched && meta.error ?  <div className="url_val"><div className="error">{meta.error}</div></div> : null}
          
        </Col>
        {/* <Col md='1'><Button></Button></Col> */}
        <Col md='5'>
          <FormCompCheckbox textTrue={textTrue} textFalse={textFalse} name={`${name}_allowance`} labelText="Erlaube Veröffentlichung" />
        </Col>
      </Row>
    </FormGroup>
  );
};

export default FormTextAllow;
