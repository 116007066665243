import React, { useEffect, useState } from 'react';
import { Button, FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import { useFormikContext } from 'formik';
import { useFileHandler } from '../../functions/formik/useFileHandler';

import FormCompButtonAddRemove from "../formik/form-button-add";

const FormFileSelectIMG = ({
  amount_input_field, label, name, dispatch, imgState, desiredWidth, desiredHeight, cropmark, unit, onChange, imgnaming, countarrayState
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [inputCount, setInputCount] = useState(amount_input_field);
  const { handleFileChange, fileInfo, errorMessage } = useFileHandler(name, cropmark, desiredWidth, desiredHeight, dispatch);

  useEffect(() => {
    console.log("Checking countarrayState and name:", countarrayState, name);
    const key = `${name}_inputCount`;
    const newCount = countarrayState?.[key];  // Use optional chaining to safely access properties
  
    console.log(`New count for ${key}:`, newCount);  // Check what you're actually getting here
  
    if (newCount !== undefined) {
      setFieldValue(`${name}_inputCount`, newCount);
      setInputCount(newCount);
    }
  }, [countarrayState, name, setFieldValue]);
  


  const addInputField = () => {
    setInputCount(currentCount => currentCount + 1);
  };

  const removeInputField = () => {
    setInputCount(currentCount => currentCount - 1);
  };

  const inputFields = () => {
    let fields = [];
    for (let i = 1; i <= inputCount; i++) {
      const currentName = `${name}_${i}`;
      const imgUrl = imgState[currentName] || ''; // Fallback if undefined

      fields.push(
        <Row key={currentName} style={{ marginBottom: '20px' }}>
          <Col md='8'>
            <div style={{ minWidth: '100%', backgroundColor: '#ededed', padding: '10px', border: "1px solid grey" }}>
              <FormGroup className="formgroup">
                {label && <FormLabel htmlFor={currentName}>
                  <div className="btn_fileselect_inf btn_fileselect_info_first">{`${label} ${i}`}</div>
                  <div className='btn_fileselect_inf'>{`Format: ${desiredWidth}${unit} x ${desiredHeight}${unit}`}</div>
                  <div className='btn_fileselect_inf'>{`Dateiname: ${fileInfo.originalFilename}`}</div>
                </FormLabel>}
                <input
                  id={currentName}
                  name={currentName}
                  type="file"
                  accept="image/*"
                  onChange={event => handleFileInputChange(event, currentName)}
                  style={{ display: "none" }}
                />
                <Button as="label" htmlFor={currentName} className='img-button'>Datei auswählen</Button>
              </FormGroup>
              {/* <FormGroup className="formgroup" style={{float:'left'}}>Neuer Dateiname</FormGroup> */}
              <input
                type="text"
                label="Filename"
                name={`${currentName}_filename`}
                value={values[`${currentName}_filename`] || `${imgnaming}_${currentName}`}
                readOnly
                className="form-text-info"
                style={{ userSelect: "none", cursor: "default" }}
              />
              
            </div>
          </Col>
          <Col>
            {imgUrl ? (
              <img src={imgUrl} alt={`Preview ${i}`} style={{ width: '100%', height: 'auto' }} />
            ) : (
              <div>Noch kein Bild ausgewählt</div>
            )}
          </Col>
        </Row>
      );
    }
    return fields;
  };

  const handleFileInputChange = (event, currentName) => {
    const file = event.currentTarget.files[0];
    if (file) {
      handleFileChange(event);  // Assuming this does additional necessary setups
      const fileExtension = file.name.split('.').pop();
      const newImgUrl = URL.createObjectURL(file);
      dispatch({ type: 'SET_IMG_URL', fieldName: currentName, imgUrl: newImgUrl });
      setFieldValue(currentName, file);

      const newFilename = `${imgnaming}_${currentName}.${fileExtension}`;
      setFieldValue(`${currentName}_filename`, newFilename);
      setFieldValue(`${name}_inputCount`, inputCount);
      console.log(`setfieldvalue ${name}_inputCount:`, inputCount);


      if (onChange) {
        onChange({ filename: newFilename });
      }
      console.log(`New image selected: ${newFilename} with URL: ${newImgUrl}`);
    }
  };

  return (
    <>
      <div>

        {inputFields()}

        <FormCompButtonAddRemove
                                                onAdd={addInputField}
                                                onRemove={removeInputField}
                                                addStyle={{ marginRight: "10px" }}
                                                removeStyle={{ marginLeft: "5px" }}
                                              />
      
      </div>
      <input
                type='text'
                name={`${name}_inputCount`} 
                value={`${inputCount}`}
                readOnly
                style={{ display: 'none' }}
                />
    </>
  );
};

export default FormFileSelectIMG;
