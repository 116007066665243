import React, { useState, useEffect } from 'react';
import { FieldArray } from 'formik';
import { selecStaffMap } from "../../const-data/const-selecStaffMap";
import { Card, Button, Row, Col, FormGroup, FormLabel } from 'react-bootstrap';
import FormCompSelect from "./form-select";
import { selecStg } from "../../functions/const-main";


//Selecting Lehrende based on sdef_studiengang_sV
const FormSelectLehrende = ({ values, onChange }) => {
  const [lehrende, setLehrende] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (selecStaffMap) {
      const processedLehrende = Object.entries(selecStaffMap).map(([key, value]) => {
        const selecStgArray = value.sdef_studiengang_sV ? value.sdef_studiengang_sV.map(stg => stg.selecStg) : [];
        return {
          value: value.fullname,
          label: value.fullname,
          selecStg: selecStgArray,
        };
      });
      setLehrende(processedLehrende);
    }
  }, [selecStaffMap]);
  

  const filterOptions = (selecStg, index) => {
    console.log(`Filtering for selecStg: ${selecStg} at index ${index}`);
    setFilters({
      ...filters,
      [index]: selecStg,
    });
  };

  const getFilteredLehrende = (index) => {
    const selecStg = filters[index];
    if (!selecStg) return lehrende;
    return lehrende.filter(item => Array.isArray(item.selecStg) && item.selecStg.includes(selecStg));
  };

  return (
    <FormGroup>
      <FormLabel>Lehrende</FormLabel>
      <FieldArray name="assignedLehrende">
        {({ push, remove }) => (
          <div>
            {values.assignedLehrende.map((item, index) => (
              <Card
                key={`lehrende_${index}`}
                style={{
                  padding: "0px!important",
                  border: "0px solid black",
                  backgroundColor: "white",
                  borderRadius: "0px!important",
                  borderBottom: "5px solid white",
                }}
              >
                <Row className="d-flex align-items-center" style={{ margin: "0px", padding: "0px" }}>
                  <Col xs='auto' md='auto' style={{ minWidth: "130px" }}>
                    <div style={{ marginTop: "auto" }}>
                      {index > 0 && (
                        <Button
                          variant="danger"
                          onClick={() => remove(index)}
                          className="btn-remove"
                          style={{ marginRight: "10px" }}
                        >
                          -
                        </Button>
                      )}
                      <Button
                        variant="primary"
                        onClick={() => push({ lehrende: "" })}
                        className="btn-add"
                        style={{ marginRight: "10px", float: "left" }}
                      >
                        +
                      </Button>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <FormCompSelect  
                      name={`assignedLehrende[${index}].ger_lehrgebiet`} 
                      labelText="" 
                      options={getFilteredLehrende(index)} 
                      onChange={onChange} 
                      showLabel={false} 
                    />
                   
                  </Col>
                  <Col xs={3} md={3}>
                  <FormCompSelect
                      name={`assignedLehrende[${index}].filter`}
                      labelText="Filter Studiengang:"
                      options={selecStg}
                      onChange={(e) => filterOptions(e.target.value, index)}
                      showLabel={false}
                    />
                  </Col>
                </Row>
              </Card>
            ))}
          </div>
        )}
      </FieldArray>
    </FormGroup>
  );
};

export default FormSelectLehrende;
