import React from 'react';

import { useField, useFormikContext } from 'formik';
import { FormGroup, FormLabel } from 'react-bootstrap';

const FormSelect = ({ name, labelText, options, onChange, showLabel = true }) => {
  const formik = useFormikContext();
  
  const [field, meta, helpers] = useField(name);
  


  const handleChange = (e) => {
    const { value } = e.target;
    helpers.setValue(value);
    if (onChange) {
      onChange(e);  // Make sure to pass the event back to parent's onChange
    }
  };

  // Apply a different CSS class if the field has a value
  const additionalClass = meta.touched && field.value ? "form-selec-selected" : "";
  const additionalClass_label = meta.touched && field.value ? "formgroup-selected" : "";

  return (
    <FormGroup className={`formgroup ${additionalClass_label}`}>
      {showLabel && 
        <FormLabel htmlFor={name}>{labelText}</FormLabel>}
        <select 
      
          {...field}
          id={name}
          className={`form-selec ${additionalClass}`}
          onChange={handleChange}
        >
          <option value="">{labelText}</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      {/* Optionally display validation errors */}
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </FormGroup>
  );
};

export default FormSelect;
