import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import Quilltest from './components/quilltest';
import MDInput from './MD-Input';

// import MDModuleInput from './MD-Module-Input';
import NavApp from './components/nav/nav-app';


import MDStaffInput from './MD-Staff-Input';
import MDStaffInputCopy from './MD-Module-Input copy';
import SeafileUploadWorking from './components/seafile_uploadWorking';

import StudentWork from './components/student-work';
import Seafile from './components/seafile';
import { Button, FormGroup, FormControl, FormLabel, FormSelect, Accordion, Card, Tab, Tabs, Row, Col } from 'react-bootstrap';
import Staff from './pages/staff-info';
// import Module from './pages/module-info';
import ModuleList from './pages/module-list';
import StdWork from './pages/std-work';
import Exhib from './pages/exh-info';
import StgInfo from './pages/stg-info';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import StudentWorkIntro from './components/react-spring/student-work-intro';

function App() {
  return (
    <div className="App">

    {/* <NavApp></NavApp> */}
    {/* <div style={{padding:'20px'}}></div> */}
    {/* <SeafileUploadWorking></SeafileUploadWorking> */}
    {/* <Seafile></Seafile> */}
    {/* <MDModuleInput> </MDModuleInput> */}
    {/* <StudentWork/> */}
    {/* <MDInput/>  */}
    <StudentWorkIntro></StudentWorkIntro>

    {/* <MDStaffInputCopy/> */}

    {/* this is the current working StaffInput */}
    {/* <MDStaffInput/> */}
    
    {/* <Quilltest/> */}

    </div>
  );
}

export default App;