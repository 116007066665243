import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import StudentWork from '../student-work';
import './StudentWorkIntro.css';  // Import the CSS file

function StudentWorkIntro() {
  const [isOpen, setIsOpen] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [wiggle, setWiggle] = useState(false);
  const [showLogos, setShowLogos] = useState(true);  // State to control the visibility of logos

  // Define the spring for the wiggle effect
  const wiggleAnimation = useSpring({
    to: [
      { rotateZ: 10 },
      { rotateZ: -10 },
      { rotateZ: 10 },
      { rotateZ: -10 },
      { rotateZ: 10 },
      { rotateZ: 0 }, // End at initial position
    ],
    from: { rotateZ: 0 },
    reset: wiggle,
    onRest: () => {
      if (wiggle) {
        setWiggle(false);  // Reset wiggle state when animation completes
        if (isOpen) {
          setShowButton(false);  // Hide the button after animation if isOpen is true
          setShowLogos(false);   // Hide logos when the animation completes if isOpen is true
        }
      }
    },
    config: { duration: 50 },  // Fast rotation speed
    loop: wiggle  // Only loop when wiggle is true, stop looping when false
  });

  // Define the spring for content visibility
  const animationStyles = useSpring({
    to: {
      opacity: isOpen && !showButton ? 1 : 0,
      transform: isOpen && !showButton ? 'scaleY(1)' : 'scaleY(0)'
    },
    from: {
      opacity: 0,
      transform: 'scaleY(0)'
    },
    display: isOpen && !showButton ? 'block' : 'none',
    config: { friction: 10, tension:210, mass: 0.5 } // Smooth animation
  });



  return (
    <>
     {showLogos && (
       <>
         <div className='logo_left'>
           <img style={{ height: '70px', margin: '20px' }} src='../../../img/icons/SVG/Logo_stiftung.svg' />
         </div>
         <div className='logo_right'>
           <img style={{ height: '50px', margin: '20px' }} src='../../../img/icons/SVG/Logo_HT_CG.svg' />
         </div>
         <div className='logo_bottoml'>Entwickelt von Alois C. Kaufmann</div>
       </>
     )}
     <div style={{
       display: 'flex', 
       justifyContent: 'center', 
       alignItems: 'center', 
       width: '100vw', 
       height: '100vh',
       position: 'absolute',
       top: 0, 
       left: 0
     }}>
      
       {showButton && (
         <div
           className="btn_abgabepaket"
           onClick={() => { setIsOpen(true); setWiggle(true); setShowLogos(false); }} // Ensure wiggle and logo hide is only set here
           role="button"
           tabIndex={0}
           onKeyPress={(event) => {
             if (event.key === 'Enter') {
               setIsOpen(true);
               setWiggle(true);
               setShowLogos(false);
             }
           }}
         >
           <animated.img
             src="./img/icons/icon_abgabepaket.svg"
             alt="File Icon"
             style={wiggleAnimation}
           />
           <p>Abgabepaket<br></br>starten</p>
         </div>
       )}
     </div>

     {isOpen && !showButton && (
       <animated.div className="animatedContent" style={animationStyles}>
         <StudentWork />
       </animated.div>
     )}
    </>
  );
}

export default StudentWorkIntro;
