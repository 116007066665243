import { useState } from 'react';
import { useFormikContext } from 'formik';
import { utilsPDF } from '../pdflib/utils-pdf';

export const useFileHandler = (name, cropmark, desiredWidth, desiredHeight, dispatch) => {
    const { setFieldValue } = useFormikContext();
    const [fileInfo, setFileInfo] = useState({ originalFilename: '', metadata: null });
    const [errorMessage, setErrorMessage] = useState("");

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            setErrorMessage("No file selected.");
            console.error("No file selected.");
            return;
        }

        // Additional validation
        // if (file.size > 10 * 1024 * 1024) { // 10 MB limit
        //     setErrorMessage("File size should not exceed 10 MB.");
        //     console.error("File size should not exceed 10 MB.");
        //     return;
        // }

        try {
            const url = URL.createObjectURL(file);
            console.log('Created URL for file:', url);
            setFieldValue(name, file);

            const fileType = file.type.split('/')[0];
            console.log('File type:', fileType);

            if (fileType === "application" && file.type === "application/pdf") {
                console.log('Processing PDF file:', file.name);
                dispatch({ type: 'SET_PDF_URL', fieldName: name, varUrl: url });

                await utilsPDF(file, (info) => {
                    setFileInfo({
                        originalFilename: file.name,
                        metadata: { pages: info } // Ensure this is how utilsPDF outputs the data
                    });
                }, setFieldValue, name, setErrorMessage, cropmark, desiredWidth, desiredHeight);
            } else if (fileType === "image") {
                console.log('Processing image file:', file.name);
                dispatch({ type: 'SET_IMG_URL', fieldName: name, varUrl: url });

                const metadata = await extractImageMetadata(file);
                setFileInfo({
                    originalFilename: file.name,
                    metadata: metadata
                });
            } else {
                console.log('Processing non-image and non-PDF file:', file.name);
                dispatch({ type: 'SET_VAR_FILE_URL', fieldName: name, varUrl: url });
                setFileInfo({
                    originalFilename: file.name,
                    metadata: null // No metadata for non-image and non-PDF files
                });
            }

            setErrorMessage(""); // Clear any previous error messages
        } catch (error) {
            console.error("Error processing file:", error);
            setErrorMessage("Failed to process file.");
        }
    };

    return { handleFileChange, fileInfo, errorMessage };
};

async function extractImageMetadata(file) {
    console.log('Extracting metadata for file:', file.name);
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height
            });
        };
        img.onerror = (error) => {
            console.error('Failed to load image metadata:', error);
            reject(new Error("Failed to load image metadata"));
        };
        img.src = URL.createObjectURL(file);
    });
}
