import {
  useField,
  Formik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  useFormikContext,
} from "formik";
import React, { useRef, useState, useEffect, useReducer } from "react";

import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import QuillEditorStaff from "./quill-staff"; // Import the QuillEditor component
import classNames from "classnames"; // Import classnames library
import axios from "axios";

import Icon_import from "./icons/icon-import";
import Icon_export from "./icons/icon-export";
import Icon_folder from "./icons/icon-folder";

import {
  seafile_uploadFileSW,
  createDirectory,
} from "../functions/const-seafile-upload-SW"; // Adjust the path as necessary

//Const Data based on .json Files
import { selecStgMap } from "../const-data/const-selecStgMap";
import { selecModMap } from "../const-data/const-selecModMap";
import { selecStaffMap } from "../const-data/const-selecStaffMap";
import { tags } from "../functions/const-tags";

import initialValues_Student_Work from "../functions/formik/initialValues-student-work";

import { getUnixTimestamp } from "../functions/func-getunixtimestamp";
import {
  parseTimestamp,
  parseTimestampFolder,
} from "../functions/const-seafile";

import FileInput from "./fileinput-studwork";
import ResolutionDisplay from "./comp-ResolutionDisplayStudWork";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormSelect,
  Accordion,
  Card,
  Tab,
  Tabs,
  Row,
  Nav,
  Col,
} from "react-bootstrap";
import JSZip from "jszip";
import {
  difficulty,
  moduldauer,
  projektart,
} from "../functions/const-stud-work";
import {
  selecStg,
  selecStgSem,
  selecStgType,
  selecSemType,
  getCustomValueofOptionList,
} from "../functions/const-main";

import FormFileSelectPDF from "./formik/form-file-pdf";
import FormFileSelectIMG from "./formik/form-file-img";
import FormFileSelectVAR from "./formik/form-file-var";

import FormCompText from "./formik/form-text";
import FormCompTextAllow from "./formik/form-text-allow";
import FormCompSelect from "./formik/form-select";
import FormCompSelectFlex from "./formik/form-select-flex";
import FormSelectLehrende from "./formik/form-select-lehrende";
import FormCompCheckbox from "./formik/form-checkbox";
import FormCompButtonAddRemove from "./formik/form-button-add";
import FormCompbuttonGeneral from "./formik/form-button-gen";

// Functions
import {
  generateYearOptions,
  getCurrentYear,
} from "../functions/formik/select-generate-year";
import { transformModuleValue } from "../functions/formik/filename-value"; // Import the externalized function

import {
  pdfReducer,
  imgReducer,
  varfileReducer,
  countarrayReducer,
} from "../functions/formik/fileReducer";

import ElementInfo from "./comp-ele-info";

const StudentWork = () => {
  const fileInputRef = useRef();
  const [pdfState, pdfDispatch] = useReducer(pdfReducer, {});
  const [imgState, imgDispatch] = useReducer(imgReducer, {});
  const [countarrayState, countarrayDispatch] = useReducer(
    countarrayReducer,
    {}
  );
  const [varfileState, varfileDispatch] = useReducer(varfileReducer, {});

  const [imageData, setImageData] = useState({});
  const [PDFfileName, setFileName] = useState(""); // State variable for the filename
  const yearOptions = generateYearOptions();
  const currentYear = getCurrentYear();

  const [uploadInfo, setUploadInfo] = useState([]);

  const [acc_Seafile_showAccordion, acc_Seafile_setShowAccordion] =
    useState(false);
  const [acc_Seafile_activeKey, acc_Seafile_setActiveKey] = useState(null);

  const [courses, setCourses] = useState([]);
  const [manualTrigger, setManualTrigger] = useState(false); // State to trigger useEffect
  const [lehrende, setLehrende] = useState([]);
  const [filteredLehrende, setFilteredLehrende] = useState([]);
  const [imgnaming] = useState([]);

  const [formValues, setFormValues] = useState({
    accordion1: {
      projektart: "",
      jahr: "",
      semestertype: "",
      selecStgType: "",
      selecStgSem: "",
      selecStg: "",
      // module: '',
    },
    accordion2: {
      ger_project_titel: "",
      ger_project_subtitel: "",
      ger_descr_projekt_short: "",
      // ger_project_tags: [{},],
    },
  });

  const [isValid, setIsValid] = useState({
    accordion1: false,
    accordion2: false,
  });

  const handleInputChange = (accordion, field, value) => {
    console.log(`Updating ${accordion} - ${field}: ${value}`);
    setFormValues((prevValues) => ({
      ...prevValues,
      [accordion]: {
        ...prevValues[accordion],
        [field]: value,
      },
    }));
  };

  //Define the prefix of all filenames genereated by the form
  const generateImgNaming = (values) => {
    // Implement your custom logic here
    const customSemestertype = getCustomValueofOptionList(
      values.semestertype,
      selecSemType
    );
    const customselecStgType = getCustomValueofOptionList(
      values.selecStgType,
      selecStgType
    );
    const transformedModule = transformModuleValue(values.modul);
    return `${values.jahr}${customSemestertype}-${customselecStgType}_${transformedModule}_${values.vorname}_${values.nachname}_${values.projektart}`;
  };

  const handleImageChange = (
    name,
    { preview, resolution, base64, filename }
  ) => {
    setImageData({
      ...imageData,
      [name]: { preview, resolution, base64, filename },
    });
  };

  // Step 4: Implement a function to handle file input change and load JSON content
  // Assume this function is now part of a component or has access to `values` from Formik
  const handleFileInputChange = async (
    event,
    setFieldValue,
    setTouched,
    setManualTrigger
  ) => {
    const file = event.target.files[0];
    console.log("Selected file:", file);

    if (file) {
      try {
        const zip = new JSZip();
        const content = await zip.loadAsync(file);
        const fileKeys = Object.keys(content.files);
        console.log("Processing ZIP file with keys:", fileKeys);

        let jsonContent = null;

        for (const key of fileKeys) {
          console.log(`File Key: ${key}`);
          if (key.endsWith(".json")) {
            const jsonString = await content.files[key].async("string");
            jsonContent = JSON.parse(jsonString);
            console.log("Parsed JSON content:", jsonContent);
            break;
          }
        }

        if (jsonContent) {
          const touchedFields = {};
          const newFormValues = { ...formValues };

          // Update all fields in JSON content
          for (const field in jsonContent) {
            if (jsonContent[field] !== undefined) {
              setFieldValue(field, jsonContent[field], true); // Validate field on change
              touchedFields[field] = true;
              console.log(
                `Set Formik field: ${field} to value: ${jsonContent[field]}`
              );

              // Update the local formValues state
              if (newFormValues.accordion1.hasOwnProperty(field)) {
                newFormValues.accordion1[field] = jsonContent[field];
              } else if (newFormValues.accordion2.hasOwnProperty(field)) {
                newFormValues.accordion2[field] = jsonContent[field];
              } else {
                // Add fields outside of accordion1 and accordion2 to the root of newFormValues
                newFormValues[field] = jsonContent[field];
              }
            }
          }

          console.log("Updated formValues:", newFormValues);

          setTouched(touchedFields);
          setFormValues(newFormValues); // Set the updated form values
          setManualTrigger((prev) => !prev); // Update manual trigger state to force useEffect re-run
          // First process and update count arrays

          await processImageFiles(
            fileKeys,
            jsonContent,
            content,
            setFieldValue
          );
          await processPDFFiles(fileKeys, jsonContent, content, setFieldValue);
          await processVarFiles(fileKeys, jsonContent, content, setFieldValue);
          await processCountArray(
            jsonContent,
            countarrayDispatch,
            setFieldValue
          );
        }
      } catch (error) {
        console.error("Error processing ZIP file:", error);
      }
    }
  };

  useEffect(() => {
    const validateAccordion = (accordion) => {
      return Object.values(formValues[accordion]).every((value) =>
        typeof value === "string" ? value.trim() !== "" : value !== ""
      );
    };

    const newIsValid = {
      accordion1: validateAccordion("accordion1"),
      accordion2: validateAccordion("accordion2"),
    };
    setIsValid(newIsValid);

    console.log("Accordion Validation State:", newIsValid);
    console.log("Form Values:", formValues);
  }, [formValues]);

  // useEffect to process courses based on selection
  useEffect(() => {
    const selectedStg = formValues.accordion1?.selecStg;
    const selectedStgType = formValues.accordion1?.selecStgType;

    if (selectedStg && selectedStgType) {
      console.log("Selected Stg:", selectedStg);
      console.log("Selected Stg Type:", selectedStgType);

      const processedCourses = Object.entries(selecModMap)
        .filter(
          ([key, value]) =>
            value.selecStg === selectedStg &&
            value.selecStgType === selectedStgType
        )
        .map(([key, value]) => ({
          value: value.modulnr,
          label: `${value.modulnr} - ${value.ger_modultitel}`,
        }));
      setCourses(processedCourses);

      console.log("Processed Courses:", processedCourses);
    } else {
      const allCourses = Object.entries(selecModMap).map(([key, value]) => ({
        value: value.modulnr,
        label: `${value.modulnr} - ${value.ger_modultitel}`,
      }));
      setCourses(allCourses);
    }
  }, [
    formValues.accordion1?.selecStg,
    formValues.accordion1?.selecStgType,
    manualTrigger,
  ]); // Add manualTrigger to dependencies

  //use effect to change accordion appearance based on validation
  useEffect(() => {
    const validateAccordion = (accordion) => {
      return Object.values(formValues[accordion]).every(
        (value) => value.trim() !== ""
      );
    };

    const newIsValid = {
      accordion1: validateAccordion("accordion1"),
      accordion2: validateAccordion("accordion2"),
    };
    setIsValid(newIsValid);

    console.log("Accordion Validation State:", newIsValid);
    console.log("Form Values:", formValues);
  }, [formValues]);

  function getMaxInputCount(jsonContent) {
    let maxInputCount = 0;
    for (const key in jsonContent) {
      if (key.endsWith("_inputCount") && typeof jsonContent[key] === "number") {
        maxInputCount = Math.max(maxInputCount, jsonContent[key]);
      }
    }
    return maxInputCount;
  }

  async function processCountArray(
    jsonContent,
    countarrayDispatch,
    setFieldValue
  ) {
    const maxCounts = {};

    // First, accumulate the maximum counts for each field
    for (const field in jsonContent) {
      if (field.endsWith("_inputCount")) {
        const baseFieldName = field.replace("_inputCount", "");
        const count = parseInt(jsonContent[field], 10);
        if (!isNaN(count)) {
          if (!maxCounts[baseFieldName] || count > maxCounts[baseFieldName]) {
            maxCounts[baseFieldName] = count;
          }
        }
      }
    }

    // Dispatch the maximum counts and update Formik values for each field
    for (const baseFieldName in maxCounts) {
      const count = maxCounts[baseFieldName];
      const fieldName = `${baseFieldName}_inputCount`; // Adjust if your naming convention differs
      countarrayDispatch({
        type: "SET_MAX_INPUT_COUNT",
        fieldName: baseFieldName,
        count: count,
      });
      setFieldValue(fieldName, count); // Set the value in Formik's state
      console.log(`Set Formik field: ${fieldName} to value: ${count}`);
    }

    console.log("highest number of inputs:", maxCounts);
    console.log(
      "Processed maximum input counts and updated state with the highest values."
    );
  }

  //function used for importing IMGs inside ZIP File
  async function processImageFiles(
    fileKeys,
    jsonContent,
    content,
    setFieldValue,
    values
  ) {
    const maxInputCount = getMaxInputCount(jsonContent); // Use the function to get the max count
    console.log(`Max Input Count: ${maxInputCount}`);

    for (const key of fileKeys) {
      console.log(`Processing Image File: ${key}`);
      if (key.match(/\.(jpg|jpeg|png|svg)$/)) {
        for (const field in jsonContent) {
          if (jsonContent[field] === key) {
            console.log(`Matching JSON field: ${field}`);
            const blob = await content.files[key].async("blob");
            const fileType = key.split(".").pop();
            const imgUrl = URL.createObjectURL(
              new Blob([blob], { type: `image/${fileType}` })
            );

            const formikField = field.replace("_filename", "");

            const imgFile = new File([blob], jsonContent[field], {
              type: `image/${fileType}`,
            });
            setFieldValue(formikField, imgFile);
            setFieldValue(`${formikField}_url`, imgUrl);
            console.log(
              `Set Formik field: ${formikField} to Image file and URL: ${imgUrl}`
            );
            imgDispatch({
              type: "SET_IMG_URL",
              fieldName: formikField,
              imgUrl,
            });
            console.log("Dispatched SET_IMG_URL action:", {
              fieldName: formikField,
              imgUrl,
            });
          }
        }
      }
    }
  }

  //function used for importing PDFs inside ZIP File
  async function processPDFFiles(
    fileKeys,
    jsonContent,
    content,
    setFieldValue
  ) {
    for (const key of fileKeys) {
      if (key.endsWith(".pdf")) {
        for (const field in jsonContent) {
          if (jsonContent[field] === key) {
            const blob = await content.files[key].async("blob");
            const pdfUrl = URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );

            const formikField = field.replace("_filename", "");

            const pdfFile = new File([blob], jsonContent[field], {
              type: "application/pdf",
            });
            setFieldValue(formikField, pdfFile);
            setFieldValue(`${formikField}_url`, pdfUrl);
            console.log(
              `Set Formik field: ${formikField} to PDF file and URL: ${pdfUrl}`
            );
            pdfDispatch({
              type: "SET_PDF_URL",
              fieldName: formikField,
              pdfUrl,
            });
            console.log("Dispatched SET_PDF_URL action:", {
              fieldName: formikField,
              pdfUrl,
            });
          }
        }
      }
    }
  }

  // Function to process any file type inside the ZIP File
  async function processVarFiles(
    fileKeys,
    jsonContent,
    content,
    setFieldValue
  ) {
    // Iterate over each file key in the array of file keys
    for (const key of fileKeys) {
      console.log(`Processing Var File: ${key}`);

      // Check if the file key does not match the specified file extensions (pdf, jpg, jpeg, png, json)
      if (!key.match(/\.(pdf|jpg|jpeg|png|svg|json)$/)) {
        // Iterate over each field in the JSON content
        for (const field in jsonContent) {
          // If the value of the JSON field matches the current file key
          if (jsonContent[field] === key) {
            console.log(`Matching JSON field: ${field}`);

            try {
              // Extract the file as a blob object from the zip content
              const blob = await content.files[key].async("blob");
              // Create a URL for the blob object
              const fileUrl = URL.createObjectURL(blob);

              // Remove the '_filename' suffix to get the formik field name
              const formikField = field.replace("_filename", "");

              // Extract the file extension from the key or use 'octet-stream' as a default type
              const fileType = key.split(".").pop() || "octet-stream";
              // Create a new File object with the blob content
              const varFile = new File([blob], jsonContent[field], {
                type: blob.type || `application/${fileType}`,
              });

              // Ensure the filename field is updated with the complete file name including extension
              const completeFileName = key; // Using the key directly to get the complete filename
              setFieldValue(`${formikField}_filename`, completeFileName);
              console.log(
                `Set Formik field: ${formikField}_filename to complete file name: ${completeFileName}`
              );

              // Dispatch an action to update the state with the file URL
              varfileDispatch({
                type: "SET_VAR_FILE_URL",
                fieldName: formikField,
                varUrl: fileUrl,
              });
              varfileDispatch({
                type: "SET_VAR_FILE_NAME",
                fieldName: formikField,
                fileName: completeFileName,
              });
              console.log(
                "Dispatched SET_VAR_FILE_URL and SET_VAR_FILE_NAME actions:",
                {
                  fieldName: formikField,
                  varUrl: fileUrl,
                  fileName: completeFileName,
                }
              );

              // Set the formik field value to the File object
              setFieldValue(formikField, varFile);
              console.log(
                `Set Formik field: ${formikField} to file and URL: ${fileUrl}`
              );
              // Set the URL of the formik field
              setFieldValue(`${formikField}_url`, fileUrl);

              // Additional verification log to check the Formik state
              console.log(`Verified Formik state for ${formikField}:`, {
                file: varFile,
                url: fileUrl,
              });
            } catch (error) {
              // Log any errors encountered during the file processing
              console.error(`Error processing file ${key}:`, error);
            }
          }
        }
      }
    }
  }

  const handleSubmitSF = async (values, setValues, setUploadInfo) => {
    console.log("EXPORT IS STARTING NOW");
    console.log("Form values:", values);

    // Debugging statements to check the values
    console.log("values.jahr:", values.jahr);
    console.log("values.selecStg:", values.selecStg);

    const timestamp = getUnixTimestamp();
    const parsed_timestamp = parseTimestampFolder(timestamp);

    // Generate directory name based on form values
    const dirName = generateImgNaming(values);
    const year = values.jahr || "unknown_year";
    const stg = values.selecStg || "unknown_stg";
    const baseUploadPath = process.env.REACT_APP_SF_up_BAMAFW_endpath || "/";
    const yearDir = `${baseUploadPath}${year}/`;
    const stgDir = `${yearDir}${stg}/`;
    const subDirPath = `${stgDir}${dirName}_${parsed_timestamp}/`;

    console.log("baseUploadPath:", baseUploadPath);
    console.log("Year Directory Path:", yearDir);
    console.log("Study Group Directory Path:", stgDir);
    console.log("Sub Directory Path:", subDirPath);

    // Check if environment variables are set correctly
    if (
      !process.env.REACT_APP_SF_proxyUrl ||
      !process.env.REACT_APP_SF_Server
    ) {
      console.error("Missing required environment variable.");
      return;
    }

    // Create directories
    await createDirectory(yearDir);
    await createDirectory(stgDir);
    await createDirectory(subDirPath);

    // Upload files concurrently
    const fileUploadPromises = Object.keys(values).map(async (key) => {
      if (values[key] instanceof File) {
        const fileField = key;
        const filenameField = `${fileField}_filename`;
        const file = values[key];
        const fileType = file.type.split("/")[1]; // Extracts the file type (pdf, jpg, png)

        console.log(`${filenameField} values:`, values[filenameField]);

        let newFileName = values[filenameField] || `defaultName.${fileType}`;
        console.log(`Uploading file: ${newFileName}`);

        // Upload the file to Seafile
        await seafile_uploadFileSW(
          file,
          newFileName,
          subDirPath,
          (progress) => {
            console.log(`Upload progress for ${newFileName}: ${progress}%`);
          },
          (info) => {
            console.log("Upload info:", info);
          }
        );
      }
    });

    await Promise.all(fileUploadPromises);

    // Combine the existing values with additional data
    const combinedData = {
      ...values,
      timestamp: timestamp,
      parsed_timestamp: parseTimestamp(timestamp),
      filename: `${dirName}_${timestamp}`,
    };

    // Stringify the combined data
    const jsonData = JSON.stringify(combinedData, null, 2);
    const jsonBlob = new Blob([jsonData], { type: "application/json" });
    const jsonFile = new File([jsonBlob], `${dirName}_data.json`);

    // Upload the JSON file to Seafile
    await seafile_uploadFileSW(
      jsonFile,
      `${dirName}_data.json`,
      subDirPath,
      (progress) => {
        console.log(`Upload progress for JSON data: ${progress}%`);
      },
      (info) => {
        console.log("Upload info:", info);
      }
    );

    // Optionally update Formik's state
    if (setValues) {
      setValues(combinedData);
    }
  };

  const handleSubmit = async (values, { setValues }) => {
    console.log("EXPORT IS STARTING NOW");
    console.log("Form values:", values); // Log to check the structure of values
    const ZIPfilename = generateImgNaming(values);
    const zip = new JSZip();

    // Dynamically find and process all relevant file fields
    Object.keys(values).forEach((key) => {
      if (values[key] instanceof File) {
        const fileField = key;
        const filenameField = `${fileField}_filename`;
        const file = values[key];
        const fileType = file.type.split("/")[1]; // Extracts the file type (pdf, jpg, png)

        console.log(`${filenameField} values:`, values[filenameField]);

        if (values[filenameField]) {
          const newFileName = values[filenameField];
          console.log(`Renaming and adding file: ${newFileName}`);
          zip.file(newFileName, file); // Save the file under the new name in the ZIP
        } else {
          console.warn(
            `Filename for '${fileField}' not provided, using default.`
          );
          const defaultFileName = `defaultName.${fileType}`;
          zip.file(defaultFileName, file); // Use a default file name if none provided
        }
      }
    });

    // Combine the existing values with additional data
    const timestamp = getUnixTimestamp();
    const combinedData = {
      ...values,
      timestamp: timestamp,
      parsed_timestamp: parseTimestamp(timestamp),
      filename: `${ZIPfilename}_${timestamp}`,
    };

    // Stringify the combined data
    const jsonData = JSON.stringify(combinedData, null, 2);

    // Add the JSON file with form data to the ZIP
    zip.file(`${ZIPfilename}_data.json`, jsonData);

    const templates = {
      insta_template: "./templates/insta-template.txt",
      metadata_template: "./templates/metadata-template.txt",
      youtube_template: "./templates/youtube-template.txt",
    };

    // Additional template to conditionally include
    if (values["check-youtube-allowance"] === true) {
      templates["DO-NOT-PUBLISH-ON-YOUTUBE"] =
        "./templates/DO-NOT-PUBLISH-ON-YOUTUBE.txt";
    }

    // Function to fetch, process, and add a template to the ZIP
    const fetchAndProcessTemplate = async (templateKey) => {
      const templatePath = templates[templateKey];
      console.log(`Fetching template from: ${templatePath}`);

      try {
        const templateResponse = await fetch(templatePath);
        const templateText = await templateResponse.text();

        const processedTemplate = templateText.replace(/{(\w+)}/g, (_, key) => {
          // Special handling for 'projektart' to use the 'templateValue'
          if (key === "projektart") {
            const selectedProject = projektart.find(
              (p) => p.value === values[key]
            );
            return selectedProject ? selectedProject.templateValue : "";
          }
          return values[key] || "";
        });

        zip.file(`${ZIPfilename}_${templateKey}.txt`, processedTemplate);
      } catch (error) {
        console.error(`Error fetching template ${templateKey}:`, error);
      }
    };

    // Fetch and process all templates
    await Promise.all(
      Object.keys(templates).map((templateKey) =>
        fetchAndProcessTemplate(templateKey)
      )
    );

    // Generate ZIP file and trigger download
    zip
      .generateAsync({ type: "blob" })
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${ZIPfilename}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error creating ZIP file:", error);
      });
  };

  return (
    <Formik initialValues={initialValues_Student_Work} onSubmit={handleSubmit}>
      {({ values, handleChange, setValues, setFieldValue, setTouched }) =>
        (() => {
          // console.log("Current Environment:", process.env.NODE_ENV);

          const imgnaming = generateImgNaming(values);
          const alumni = values.alumni;

          //combination of onchange effects for lehrende field
          //handleSelect for Lehrende Field
          const handleSelectChange = (e) => {
            const { value } = e.target;
            console.log("Selected Value:", value);
            setFieldValue("accordion1.lehrende", value);
          };

          return (
            <Form>
              <FormGroup>
                <div style={{ backgroundColor: " rgb(236, 236, 236)" }}>
                  <Row>
                    <Col
                      xs="auto"
                      md="auto"
                      className="d-flex justify-content-center"
                    >
                      <img
                        src="./img/icons/icon_abgabepaket.svg"
                        style={{ width: "150px", padding: "15px" }}
                        className="icon_up"
                        alt="File Icon"
                      />
                    </Col>
                    <Col className="d-flex align-items-center">
                      <h1 style={{ color: "#A50034" }}>Abgabepaket</h1>
                    </Col>
                    <Col className="d-flex align-items-center" md="auto" xs="6">
                      <Button
                        variant="primary"
                        type="button"
                        className="custom-button"
                        style={{
                          height: "60%",
                          minWidth: "150px",
                          width: "100%",
                          minHeight: "50px",
                        }}
                        onClick={() => {
                          acc_Seafile_setShowAccordion(true); // Toggle visibility state
                          acc_Seafile_setActiveKey("0"); // Set the Accordion item to open automatically
                          handleSubmit(values, setValues);
                        }}
                      >
                        <Icon_export width="18px" /> Export .zip
                      </Button>
                    </Col>
                    <Col className="d-flex align-items-center" md="auto" xs="6">
                      <Button
                        variant="primary"
                        onClick={() => fileInputRef.current.click()}
                        className="custom-button"
                        style={{
                          height: "60%",
                          minWidth: "150px",
                          width: "100%",
                          minHeight: "50px",
                        }}
                      >
                        <Icon_import width="18px" />Import .zip
                      </Button>
                    </Col>
                  </Row>
                </div>

                {process.env.NODE_ENV === "development" && (
                  <div
                    style={{
                      backgroundColor: " rgb(216, 246, 236)",
                      marginTop: "10px",
                      padding: "20px",
                    }}
                  >
                    <div>
                      <b>Dateibenennung Vorschau:</b> {imgnaming}
                    </div>
                  </div>
                )}

                <div style={{ marginBottom: "0px" }}>
                  <input
                    type="file"
                    accept=".json,.zip"
                    onChange={(event) =>
                      handleFileInputChange(
                        event,
                        setFieldValue,
                        setTouched,
                        setManualTrigger
                      )
                    }
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                </div>
              </FormGroup>
              <Row>
                <Col md="6" xs='12'>

                  <FormCompCheckbox
                    name="eng_transcr"
                    labelText="Englische Übersetzung hinterlegen"
                  />
                </Col>

                <Col md="6" xs='12'>
                  <FormCompCheckbox
                    name="alumni"
                    labelText="Ich bin MA / BA AbsolventIn oder mache dieses Semester meinen Abschluss"
                  />
                </Col>
              </Row>

              <Row>
                {acc_Seafile_showAccordion === true && (
                  <Accordion flush activeKey={acc_Seafile_activeKey}>
                    <Accordion.Item eventKey="0" className="acc_upload">
                      <Accordion.Header>
                        <img
                          src="./img/icons/icon_upload.svg"
                          className="icon_up"
                          alt="File Icon"
                        />
                        <h2>Seafile Upload</h2>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Bitte lade das eben exportierte .zip auf Seafile hoch.
                          Du findest das Zip in deinem Downloadordner
                        </p>
                        <iframe
                          src="https://seafile.rlp.net/u/d/b9ac2bcdaf5245269954/"
                          width="100%"
                          height="600"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </Row>

              <div style={{ paddingTop: '30px', paddingBottom: '10px' }}>
                <h1><Icon_export width="42px" /> Metadaten</h1>
              </div>
              <Accordion
                flush
                className={classNames("accordion-flush", {
                  "accordion-filled": isValid.accordion1,
                })}
              >
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h2>Kontextangaben</h2>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <Row>
                          <Col md="4" xs='12'>
                            <FormCompSelect
                              name="projektart"
                              labelText="Projektart:"
                              showLabel={false}
                              options={projektart}
                              onChange={(e) => {
                                const { value } = e.target;
                                handleInputChange(
                                  "accordion1",
                                  "projektart",
                                  value
                                );
                                setFieldValue("alumni", value === "FW");
                              }}
                            />
                          </Col>
                          <Col md="4" xs='12'>
                            <FormCompSelect
                              showLabel={false}
                              name="jahr"
                              labelText="Jahr:"
                              options={yearOptions}
                              onChange={(e) => {
                                const { value } = e.target;
                                handleInputChange(
                                  "accordion1",
                                  "jahr",
                                  value,
                                  (val) => {
                                    setFieldValue("jahr", val);
                                  }
                                );
                              }}
                            />
                          </Col>
                          <Col md="4" xs='12'>
                            <FormCompSelect
                              showLabel={false}
                              name="semestertype"
                              labelText="WiSe / SoSe:"
                              options={selecSemType}
                              onChange={(e) => {
                                const { value } = e.target;
                                handleInputChange(
                                  "accordion1",
                                  "semestertype",
                                  value
                                );
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col >
                            <Row>
                              <Col md="4" xs='12'>
                                <FormCompSelect
                                  showLabel={false}
                                  name="selecStgType"
                                  labelText="Typus:"
                                  options={selecStgType}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    console.log(
                                      "selecStgType selected:",
                                      value
                                    );
                                    handleInputChange(
                                      "accordion1",
                                      "selecStgType",
                                      value
                                    );
                                  }}
                                />
                              </Col>
                              <Col md="4" xs='12'>
                                <FormCompSelect
                                  showLabel={false}
                                  name="selecStgSem"
                                  labelText="Semester:"
                                  options={selecStgSem}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    handleInputChange(
                                      "accordion1",
                                      "selecStgSem",
                                      value
                                    );
                                  }}
                                />
                                <ElementInfo
                                  Infotitle={
                                    "Wähle das Semester aus in welchem du dich gerade befindest oder die maximale Regelstudienzeit aufweist. Z.B. Kommunikationsdesign 7 Semester"
                                  }
                                  Infodescr={""}
                                ></ElementInfo>
                              </Col>
                              <Col md="4" xs='12'>
                                <FormCompSelect
                                  showLabel={false}
                                  name="selecStg"
                                  labelText="Studiengang:"
                                  options={selecStg}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    console.log("selecStg selected:", value);
                                    handleInputChange(
                                      "accordion1",
                                      "selecStg",
                                      value
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4" xs='12'>
                            <FormCompSelect
                              name="modul"
                              labelText="Modul:"
                              options={courses}
                              showLabel={false}
                              onChange={handleSelectChange}
                            />
                          </Col>
                          <Col md="8" xs='12'>
                            <FormSelectLehrende
                              values={values}
                              lehrende={lehrende}
                              filteredLehrende={filteredLehrende}
                              onChange={handleSelectChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion
                flush
                className={classNames("accordion-flush", {
                  "accordion-filled": isValid.accordion2,
                })}
              >
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h2>Angaben zu deinem Projekt</h2>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md="6" xs='12'>
                        <Row>
                          <FormCompCheckbox
                            name="eng_transcr"
                            labelText="Englische Übersetzung hinterlegen"
                          />
                          <Col md="6" xs='12'>
                            <FormCompText
                              name="ger_project_titel"
                              labelText="Projekttitel:"
                              onChange={(e) => {
                                const { value } = e.target;
                                handleInputChange(
                                  "accordion2",
                                  "ger_project_titel",
                                  value
                                );
                              }}
                            />
                            <FormCompText
                              name="ger_project_subtitel"
                              labelText="Untertitel / Claim:"
                              onChange={(e) => {
                                handleChange(e);
                                handleInputChange(
                                  "accordion2",
                                  "ger_project_subtitel",
                                  e.target.value
                                );
                              }}
                            />
                          </Col>
                          <Col md="6" xs='12'>
                            {values.eng_transcr && (
                              <>
                                <FormCompText
                                  name="eng_project_titel"
                                  labelText="Englischer Projekttitel:"
                                />
                                <FormCompText
                                  name="eng_project_subtitel"
                                  labelText="Englischer Untertitel / Claim:"
                                />
                              </>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col md="6" xs='12'>
                        <FormLabel
                          htmlFor="ger_project_tags"
                          className="formgroup"
                        >
                          Schlagwörter / Tags
                        </FormLabel>
                        <FormCompSelectFlex
                          name="ger_project_tags"
                          labelText=""
                          options={tags}
                          isCreatable={true}
                          isMulti={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" xs='12'>
                        <FormGroup className="formgroup">
                          <FormLabel>Kurzbeschreibung</FormLabel>
                          <QuillEditorStaff
                            maxChars={600}
                            value={values.ger_descr_projekt_short} // Pass the correct field from Formik values
                            onChange={(html) => {
                              setFieldValue("ger_descr_projekt_short", html); // Correctly update the field in Formik
                              handleInputChange(
                                "accordion2",
                                "ger_descr_projekt_short",
                                html
                              ); // Call handleInputChange
                            }}
                            fieldname="ger_descr_projekt_short" // Correct the field name (remove the colon)
                          />
                        </FormGroup>

                        {values.eng_transcr && (
                          <FormGroup className="formgroup">
                            <FormLabel>Kurzbeschreibung (eng)</FormLabel>
                            <QuillEditorStaff
                              maxChars={600}
                              value={values.eng_descr_projekt_short} // Pass the correct field from Formik values
                              onChange={(html) => {
                                setFieldValue("eng_descr_projekt_short", html); // Correctly update the field in Formik
                              }}
                              fieldname="eng_descr_projekt_short" // Correct the field name (remove the colon)
                            />
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="6" xs='12'>
                        <FormCompCheckbox
                          name="descr_long"
                          labelText="Ausführliche Beschreibung hinterlegen"
                        />
                        {values.descr_long && (
                          <>
                            <FormGroup className="formgroup">
                              <FormLabel>Ausführliche Beschreibung</FormLabel>
                              <QuillEditorStaff
                                maxChars={3000}
                                value={values.ger_descr_projekt_long}
                                onChange={(html) =>
                                  setFieldValue("ger_descr_projekt_long", html)
                                }
                                fieldname="ger_descr_projekt_long"
                              />
                            </FormGroup>

                            <ElementInfo
                              Infotitle={
                                "Die ausführliche Beschreibung wird z.B. bei Printpublikationen verwendet. Der Umfang orientiert sich ungefähr an einer A4 Seite."
                              }
                              Infodescr={""}
                            ></ElementInfo>
                          </>
                        )}

                        {values.eng_transcr && values.descr_long && (
                          <FormGroup className="formgroup">
                            <FormLabel>
                              Ausführliche Beschreibung (eng)
                            </FormLabel>
                            <QuillEditorStaff
                              maxChars={3000}
                              value={values.eng_descr_projekt_long}
                              onChange={(html) =>
                                setFieldValue("eng_descr_projekt_long", html)
                              }
                              fieldname="eng_descr_projekt_long"
                            />
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h2>persönliche Kontaktdaten</h2>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <FormCompText name="vorname" labelText="Vorname:" />
                          </Col>

                          <Col>
                            {" "}
                            <FormCompText
                              name="nachname"
                              labelText="Nachname:"
                            />
                          </Col>
                        </Row>
                        <FormCompText
                          name="email_hochschule"
                          labelText="Hochschul-Email:"
                        />
                        <ElementInfo
                          Infotitle={
                            "Bitte vollständige URL bei den Links hinterlegen"
                          }
                          Infodescr={""}
                        ></ElementInfo>
                      </Col>
                      <Col>

                        <FormCompTextAllow
                          name="url_homepage"
                          urlval={true}
                          textTrue="Veröffentlichung erlaubt"
                          textFalse="Veröffentlichung nicht erlaubt"
                          labelText="deine persönliche Webseite (link):"
                        />


                      </Col>
                    </Row>

                    <Row>
                      {alumni === true && (
                        <>
                          <h3>Alumni Angaben</h3>

                          <Col>
                            {/* <FormCompText name="email_private"  labelText=" Private Emailadresse:" /> */}
                            <ElementInfo
                              Infotitle={"Wozu dienen diese Angaben?"}
                              Infodescr={""}
                            >
                              Um bei Veröffentlichungen auch auf eure Social
                              Media Profile hinweisen zu können benötigen wir
                              entsprechende Angaben. Solltet ihr zwar euer
                              Profil mitteilen wollen aber bei
                              Veröffentlichungen nicht publiziert werden klickt
                              den Button auf der jeweiligen rechten Seite an.
                            </ElementInfo>
                            <h4>Social Media</h4>
                            <Row className="align-items-stretch">
                              <Col>
                                <FormCompTextAllow
                                  name="url_insta"
                                  url="true"
                                  textTrue="Veröffentlichung erlaubt"
                                  textFalse="Veröffentlichung nicht erlaubt"
                                  labelText="Instagram (link):"
                                  urlval={true}
                                />
                              </Col>
                            </Row>
                            <Row className="align-items-stretch">
                              <Col>
                                <FormCompTextAllow
                                  name="url_behance"
                                  textTrue="Veröffentlichung erlaubt"
                                  textFalse="Veröffentlichung nicht erlaubt"
                                  labelText="Behance (link):"
                                  urlval={true}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <FormCompTextAllow
                              name="url_linkedin"
                              labelText="LinkedIn (link):"
                              textTrue="Veröffentlichung erlaubt"
                              textFalse="Veröffentlichung nicht erlaubt"
                              urlval={true}
                            />
                            <ElementInfo
                              Infotitle={"Der Linkedin Alumni Gruppe beitreten"}
                              Infodescr={""}
                            >
                              Unter dem folgenden{" "}
                              <a
                                href="https://www.linkedin.com/groups/9084289/"
                                target="_blank"
                              >
                                Link
                              </a>{" "}
                              kannst du der Alumnigruppe beitreten.
                            </ElementInfo>
                            <Col>
                              <FormCompTextAllow
                                name="url_xing"
                                urlval={true}
                                labelText="Xing (link):"
                                textTrue="Veröffentlichung erlaubt"
                                textFalse="Veröffentlichung nicht erlaubt"
                              />
                            </Col>
                          </Col>
                          <Row>
                            <Col>
                              <h4>Veröffentlichtung</h4>
                              <FormCompCheckbox
                                name="check_youtube_allowance"
                                textTrue="Veröffentlichung des Videos auf Youtube erlaubt"
                                textFalse="Veröffentlichung des Videos auf Youtube ist nicht erlaubt"
                                labelText="Ich möchte nicht dass mein Video auf Youtube veröffentlicht wird"
                              />
                              {/* <FormCompCheckbox name="check-youtube-allowance" labelText="Ich möchte nicht dass mein Video auf Youtube veröffentlicht wird" /> */}
                              {values.check_youtube_allowance === false && (
                                <>
                                  <div>
                                    Bitte hinterlege eine kurze Begründung warum
                                    du das Video nicht veröffentlichen möchtest
                                  </div>
                                  <QuillEditorStaff
                                    maxChars={300}
                                    value={
                                      values.ger_descr_check_youtube_allowance
                                    } // Pass the correct field from Formik values
                                    onChange={(html) => {
                                      setFieldValue(
                                        "ger_descr_check_youtube_allowance",
                                        html
                                      ); // Correctly update the field in Formik
                                      handleInputChange(
                                        "accordion2",
                                        "ger_descr_check_youtube_allowance",
                                        html
                                      ); // Call handleInputChange
                                    }}
                                    fieldname="ger_descr_check_youtube_allowance" // Correct the field name (remove the colon)
                                  />
                                </>
                              )}
                            </Col>
                            <Col></Col>
                          </Row>
                        </>
                      )}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>



              <div style={{ paddingTop: '30px', paddingBottom: '10px' }}>
                <h1><Icon_folder width="38px" /> Dateien</h1>
              </div>
              <Accordion flush>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h2>PDF Dateien</h2>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <FormFileSelectPDF
                          amount_input_field={4} // Example: you want three image inputs
                          label="PDF Plakat"
                          name={"pdf_plakat_1"}
                          // onFilenameChange={handleFilenameChange("pdf_plakat_1")}
                          imgnaming={imgnaming}
                          desiredWidth={70} // Desired width in centimeters
                          desiredHeight={100} // Desired height in centimeters
                          unit={"cm"}
                          dispatch={pdfDispatch}
                          pdfState={pdfState}
                          cropmark={true} // Enable crop mark checking
                        ></FormFileSelectPDF>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion flush>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h2>Bilder</h2>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <p>
                        Hinterlege hier die Bilder für die einzelnen
                        Anwendungsbereiche
                      </p>
                    </Row>
                    <Tabs
                      defaultActiveKey="profile"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="home" title="Webseite">
                        <Row className="m-1"></Row>
                        <Row className="gap-3">
                          <Col>
                            <FormFileSelectIMG
                              amount_input_field={4} // Example: you want three image inputs
                              label={`Webseite`}
                              name={`img_Web`}
                              imgnaming={imgnaming}
                              desiredWidth={1920}
                              desiredHeight={1080}
                              unit="px"
                              dispatch={imgDispatch} // Make sure these are defined or passed as props
                              imgState={imgState} // Make sure these are defined or passed as props
                              cropmark={false}
                            />
                            {/* <FormFileSelectIMG
                              label="IMG Webseite 1"
                              name={"img_Web_1"}
                              // onFilenameChange={handleFilenameChange("pdf_plakat_1")}
                              imgnaming={imgnaming}
                              desiredWidth={1920} // Desired width in centimeters
                              desiredHeight={1080} // Desired height in centimeters
                              unit={'px'}
                              dispatch={imgDispatch}
                              imgState={imgState}
                              cropmark={true} // Enable crop mark checking
                            ></FormFileSelectIMG> */}
                          </Col>
                        </Row>
                      </Tab>

                      <Tab eventKey="insta" title="Instagram">
                        <Row>
                          <Col>
                            <FormFileSelectIMG
                              amount_input_field={4} // Example: you want three image inputs
                              label={`Instagram`}
                              name={`img_Insta`}
                              imgnaming={imgnaming}
                              desiredWidth={1920}
                              desiredHeight={1920}
                              unit="px"
                              dispatch={imgDispatch} // Make sure these are defined or passed as props
                              imgState={imgState} // Make sure these are defined or passed as props
                              cropmark={false}
                            />

                            <FormFileSelectVAR
                              label="Instagram Video"
                              name="vid_insta"
                              imgnaming={imgnaming}
                              dispatch={varfileDispatch}
                              varfileState={varfileState}
                            ></FormFileSelectVAR>
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="contact" title="Print">
                        <Row>
                          <Col>
                            <div>
                              <FormFileSelectIMG
                                amount_input_field={5} // Example: you want three image inputs
                                label={`Print`}
                                name={`img_Print`}
                                imgnaming={imgnaming}
                                desiredWidth={3508}
                                desiredHeight={2500}
                                unit="px"
                                dispatch={imgDispatch} // Make sure these are defined or passed as props
                                imgState={imgState} // Make sure these are defined or passed as props
                                cropmark={false}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h2>Zusätzliche Dateien</h2>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <p>
                        Hinterlege hier weitere Dateien für deine Abgabe.
                        Beachte hierbei die individuellen Vorgaben der Lehrenden
                      </p>
                      <Col>
                        <FormFileSelectVAR
                          amount_input_field={2} // Example: you want three image inputs
                          label={`Datei`}
                          name={`file`}
                          imgnaming={imgnaming}
                          dispatch={varfileDispatch}
                          varfileState={varfileState}
                        ></FormFileSelectVAR>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <div style={{ paddingTop: '30px', paddingBottom: '10px', backgroundColor: '' }} >
                <h1><Icon_folder width="38px" />Feedback</h1>
                <p>Hinterlasse uns hier eine Rückmeldung zum Abgabepaket. Wir versuchen das Programm konstant zu verbessern.</p>
                <QuillEditorStaff
                  maxChars={600}
                  value={values.ger_feedback} // Pass the correct field from Formik values
                  onChange={(html) => {
                    setFieldValue("ger_feedback", html); // Correctly update the field in Formik
                    handleInputChange(
                      "ger_feedback",
                      html
                    ); // Call handleInputChange
                  }}
                  fieldname="ger_feedback" // Correct the field name (remove the colon)
                />
              </div>
            </Form>
          );
        })()
      }
    </Formik>
  );
};

export default StudentWork;
