import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const QuillEditorStaff = ({ value, onChange, fieldname, maxChars }) => {
  const [editorHtml, setEditorHtml] = useState(value);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setEditorHtml(value);
    setCharCount(value.length); // Update initial character count
  }, [value]);

  const handleChange = (content, delta, source, editor) => {
    const currentLength = editor.getLength();
    if (currentLength <= maxChars + 1) {
      setEditorHtml(content);
      setCharCount(currentLength - 1); // Update character count
      onChange(content);
    } else {
      const truncatedContent = content.substring(0, maxChars);
      setEditorHtml(truncatedContent);
      setCharCount(maxChars); // Update character count
      onChange(truncatedContent);
      console.log("Character limit reached");
    }
  };

  const charCountClass = charCount > maxChars ? 'quillcount limit-reached' : 'quillcount';
  
  return (
    <div>
      <ReactQuill
        className="quillEditor"
        value={editorHtml}
        onChange={handleChange}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
 
            ['link'],
          ],
        }}
      />
      <div className={charCountClass}>
        Anzahl Zeichen: {charCount} / {maxChars}
      </div>
    </div>
  );
};

export default QuillEditorStaff;
