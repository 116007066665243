import React, { useState } from 'react';
import { useField, Field, useFormikContext } from "formik";
import { FormGroup, FormLabel, Button, Row, Col } from "react-bootstrap";
import ResolutionDisplay from "./comp-ResolutionDisplay"; // Import ResolutionDisplay component
import { file } from 'jszip';


const FileInputStud = ({
  label,
  setBase64,
  enableBase64 = false,
  setImageResolution,
  imgnaming,
  onChange,
  imageData,
  ...props
}) => {
  const { setFieldValue } = useFormikContext(); // Get Formik context
  const [field, meta, helpers] = useField(props);
  const [fileName, setFileName] = useState(''); // State variable for the filename
  const [dynamicFileName, setDynamicFileName] = useState('');
  const [originalFileName, setOriginalFileName] = useState(''); // State variable for the original filename


  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const filenameimg = field.name + '_' + imgnaming;


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setOriginalFileName(file.name); // Set the original filename state
      const fileType = file.type; // Retrieve the file type
      const fileExtension = file.name.split('.').pop(); // Extract the file extension
  
      // Differentiate between 'img_' and 'file_' and redefine filename
      let newFileName;
      if (field.name.startsWith("img_")) {
        newFileName = filenameimg + '.' + fileExtension; // For images
      } else if (field.name.startsWith("file_")) {
        newFileName = filenameimg + '_nowfilename_' + file.name; // Adjusted concatenation
        console.log("New filename for file:", newFileName);
        //this is not working! or the newFilename doesnt get applied for the field
      }
      console.log("handleFileChange - New filename set:", newFileName);
      
      // Update the filename state
      setFileName(newFileName);
      setDynamicFileName(newFileName);
      helpers.setValue(file);
      setFieldValue(`${field.name}_fileName`, newFileName); // Update Formik state with new filename
      
      const url = URL.createObjectURL(file);
      const img = new Image();

      img.onload = () => {
        // Only set resolution after the image has loaded
        const resolution = { width: img.width, height: img.height };
        if (setImageResolution) {
          setImageResolution(resolution);
        }
        
        if (onChange) {
          console.log("handleFileChange - Calling onChange with:", {
            filename: newFileName,
            preview: url,
            resolution: resolution,
            base64: "",
            fileType: fileType
          });
          onChange({
            filename: newFileName,
            preview: url,
            resolution: resolution,
            base64: "",
            fileType: fileType
          });
        }
      };
      img.onerror = () => {
        if (setImageResolution) {
          setImageResolution(null);
        }
        if (onChange) {
          
          onChange({ filename: null, preview: null, resolution: null, base64: null, fileType: null });
        }
      };
      img.src = url;
  
      let base64String = "";
      if (enableBase64) {
        try {
          base64String = await convertToBase64(file);
          console.log("New filename:", newFileName);
          if (onChange) {
            
            onChange({
              filename: newFileName, // Include the new filename
              preview: url,
              resolution: img.onload ? { width: img.width, height: img.height } : null,
              base64: base64String,
              fileType: fileType
            });
          }
        } catch (error) {
          console.error("Error during file conversion to Base64", error);
        }
      }
    } else {
      setFileName(''); // Reset the filename state if no file is selected
      helpers.setValue(null);
      setFieldValue(`${field.name}_fileName`, ''); // Reset the filename in Formik state
      if (onChange) {
        onChange({ filename: null, preview: null, resolution: null, base64: null, fileType: null });
      }
    }
  };

  
  const isDataUrl = (str) => {
    // Ensure str is a string before calling startsWith
    return typeof str === 'string' && str.startsWith('data:');
  };




  return (
    <FormGroup>
      <Row style={{ backgroundColor: "" }} >
        <Col>
          <div
            className="helper"
            style={{
              display: "flex", // Enable Flexbox
              alignItems: "center", // Vertically center content
              justifyContent: "left", // Horizontally center content (optional, based on your needs)
              minWidth: "100%",
              color: "black",
              backgroundColor: "#E0E0E0",
              minHeight: "40px",
              paddingLeft: "20px",
            }}
            >
            {label}
          </div>
          
        </Col>{" "}
        <Col xs="auto">
          <input
            id={props.id || props.name}
            {...props}
            type="file"
            nameoffile = {setFileName}
            onChange={handleFileChange}
            onBlur={field.onBlur}
            style={{ display: "none" }}
          />

          <Button as="label" htmlFor={props.id || props.name} variant="primary">
            Datei auswählen
          </Button>
          {meta.touched && meta.error && (
            <div className="error">{meta.error}</div>
          )}

        </Col>

        <div>
        {fileName && (
              <div style={{ borderLeft: '1px solid black', paddingLeft: '10px', fontWeight: '200', marginLeft: '0px' }}>
                Dateibezeichnung: {fileName}
                {originalFileName && (
                  <div style={{ borderTop: '1px solid black', paddingTop: '10px', marginTop:'10px' }}>
                    Originaler Dateiname:<br></br> {originalFileName}
                  </div>
                )}
              
              </div>
            )}
        </div>

      </Row>
      <hr></hr>
      <Row>
        {/* Check if the field value is a data URL and use it as the image source */}
        {isDataUrl(field.value) && (
          <img
            src={field.value}
            alt={`${props.name} preview`}
            style={{ width: "100%", height: "auto" }}
          />
        )}

        <>

          {!isDataUrl(field.value) && imageData && imageData.preview && (
            <div>
              {props.name}
              <img
                src={imageData.preview}
                alt={`${props.name} preview`}
                style={{ width: "100%", height: "auto" }}
              />
            </div>

          )}
        </>

      </Row>
      {/* Preview and Resolution Display */}
    </FormGroup>
  );
};

export default FileInputStud;
